<template>
  <section class="international-trade">
    <div class="international-trade__container _container">
      <div class="international-trade__content">
        <div
          class="international-trade__text international-trade__column"
          data-aos="fade-right"
        >
          <div class="international-trade__header section__title">
            <div class="vertical-line"></div>
            <div class="international-trade__title">
              {{ $t("international_trade.title") }}
            </div>
          </div>
          <div v-html="$t('international_trade.text')"></div>
        </div>
        <div
          class="international-trade__image international-trade__column"
          data-aos="fade-left"
        >
          <VueSlickCarousel
            :fade="true"
            :arrows="true"
            class="images"
            :infinite="true"
          >
            <div class="image">
              <picture>
                <source
                  srcset="
                    ../assets/images/brands(580x440).jpg,
                    ../assets/images/brands(580x440)2x.jpg 2x
                  "
                  type="image/jpeg"
                  media="(min-width: 1280px)"
                />
                <source
                  srcset="
                    ../assets/images/brands(478x390).jpg,
                    ../assets/images/brands(478x390)2x.jpg 2x
                  "
                  type="image/jpeg"
                  media="(min-width: 992px)"
                />
                <source
                  srcset="
                    ../assets/images/brands(357x320).jpg,
                    ../assets/images/brands(357x320)2x.jpg 2x
                  "
                  type="image/jpeg"
                  media="(min-width: 768px)"
                />
                <source
                  srcset="
                    ../assets/images/brands(340x260).jpg,
                    ../assets/images/brands(340x260)2x.jpg 2x
                  "
                  type="image/jpeg"
                  media="(max-width: 767px)"
                />
                <img
                  src="../assets/images/brands(580x440).jpg"
                  alt="Stock"
                  loading="lazy"
                />
              </picture>
            </div>
            <div class="image">
              <picture>
                <source
                  srcset="
                    ../assets/images/stock(580x440).jpg,
                    ../assets/images/stock(580x440)2x.jpg 2x
                  "
                  type="image/jpeg"
                  media="(min-width: 1280px)"
                />
                <source
                  srcset="
                    ../assets/images/stock(478x390).jpg,
                    ../assets/images/stock(478x390)2x.jpg 2x
                  "
                  type="image/jpeg"
                  media="(min-width: 992px)"
                />
                <source
                  srcset="
                    ../assets/images/stock(357x320).jpg,
                    ../assets/images/stock(357x320)2x.jpg 2x
                  "
                  type="image/jpeg"
                  media="(min-width: 768px)"
                />
                <source
                  srcset="
                    ../assets/images/stock(340x260).jpg,
                    ../assets/images/stock(340x260)2x.jpg 2x
                  "
                  type="image/jpeg"
                  media="(max-width: 767px)"
                />
                <img
                  src="../assets/images/stock(580x440).jpg"
                  alt="Stock"
                  loading="lazy"
                />
              </picture>
            </div>
          </VueSlickCarousel>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "VInternationalTrade",
  components: { VueSlickCarousel },
  mounted() {
    this.$nextTick(this.$forceUpdate);
  }
};
</script>

<style lang="scss">
.international-trade {
  padding-top: 60px;
  @include for-tablet-portrait-up {
    padding-top: 80px;
  }
  @include for-tablet-landscape-up {
    padding-top: 90px;
  }
  @include for-desktop-up {
    padding-top: 170px;
  }
  .slick {
    &-slider {
      height: 100%;
    }
    &-list {
      height: 100%;
    }
    &-track {
      height: 100%;
    }
    &-arrow {
      position: absolute;
      // top: calc(100% + 20px);
      top: -30px;
      @include for-tablet-portrait-up {
        top: 100%;
      }
      @include for-tablet-landscape-up {
        top: calc(100% - 15px);
      }
      &.slick-prev {
        left: 0;
        @include for-tablet-portrait-up {
          left: calc(-24px - 100%);
        }
        @include for-tablet-landscape-up {
          left: calc(-36px - 100%);
        }
        @include for-desktop-up {
          left: calc(-40px - 100%);
        }
        &::before {
          content: url("../assets/icons/prev-arrow.svg");
        }
      }
      &.slick-next {
        left: 70px;
        @include for-tablet-portrait-up {
          left: calc(44px - 100%);
        }
        @include for-tablet-landscape-up {
          left: calc(36px - 100%);
        }
        @include for-desktop-up {
          left: calc(40px - 100%);
        }
        &:before {
          content: url("../assets/icons/next-arrow.svg");
        }
      }
    }
  }
  &__header {
    padding-bottom: 24px;
    @include for-tablet-portrait-up {
      padding-bottom: 16px;
    }
    @include for-tablet-landscape-up {
      padding-bottom: 40px;
    }
    @include for-desktop-up {
      padding-bottom: 50px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    @include for-tablet-portrait-up {
      flex-direction: row;
      justify-content: space-between;
    }
  }
  &__column {
    @include for-tablet-portrait-up {
      width: calc((100% - 24px) / 2);
    }
    @include for-tablet-landscape-up {
      width: calc((100% - 36px) / 2);
    }
    @include for-desktop-up {
      width: calc((100% - 40px) / 2);
    }
  }
  &__column + &__column {
    margin-top: 60px;
    @include for-tablet-portrait-up {
      margin-top: 0px;
      margin-left: 24px;
    }
    @include for-tablet-landscape-up {
      margin-left: 36px;
    }
    @include for-desktop-up {
      margin-left: 40px;
    }
  }
  &__text {
    font-family: "Manrope", sans-serif;
    @include P4();
    @include for-tablet-landscape-up {
      @include P3();
    }
    p + p {
      margin-top: 8px;
      @include for-tablet-portrait-up {
        margin-top: 10px;
      }
      @include for-desktop-up {
        margin-top: 20px;
      }
    }
  }
  &__image {
    position: relative;
    // padding: 0 0 58% 0;
    min-height: 260px;
    height: 260px;
    max-width: 340px;
    // overflow: hidden;
    // margin: 0;
    @include for-tablet-portrait-up {
      padding: 0;
      min-height: 320px;
      height: auto;
      max-width: none;
    }
    @include for-tablet-landscape-up {
      min-height: 390px;
    }
    @include for-desktop-up {
      min-height: 440px;
    }
    img,
    source {
      height: 100%;
      // object-fit: cover;
      object-position: center;
      // position: absolute;
      // top: 0;
      // left: 0;
      @include for-tablet-portrait-up {
        width: 100%;
      }
    }
  }
}
</style>
