<template>
  <section class="consultation">
    <div class="consultation__container _container">
      <v-consultation-form class="consultation__column" data-aos="fade-right"></v-consultation-form>
      <v-managers-contacts class="consultation__column" data-aos="fade-left"></v-managers-contacts>
    </div>
  </section>
</template>

<script>
import VConsultationForm from "../components/VConsultationForm";
import VManagersContacts from "../components/VManagersContacts";

export default {
  name: "VConsultation",
  components: { VConsultationForm, VManagersContacts }
};
</script>

<style lang="scss" scoped>
.consultation {
  &__container {
    padding-top: 60px;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    @include for-tablet-portrait-up {
      padding-top: 80px;
      padding-bottom: 80px;
      flex-direction: row;
    }
    @include for-tablet-landscape-up {
      padding-top: 95px;
      padding-bottom: 90px;
    }
    @include for-desktop-up {
      padding-top: 130px;
      padding-bottom: 80px;
    }
  }
    &__column {
    @include for-tablet-portrait-up {
      width: calc((100% - 24px) / 2);
    }
    @include for-tablet-landscape-up {
      width: calc((100% - 36px) / 2);
    }
    @include for-desktop-up {
      width: calc((100% - 60px) / 2);
    }
  }
  &__column + &__column {
    margin-top: 60px;
    @include for-tablet-portrait-up {
      margin-top: 0px;
      margin-left: 24px;
    }
    @include for-tablet-landscape-up {
      margin-left: 36px;
    }
    @include for-desktop-up {
      margin-left: 60px;
    }
  }
}
</style>
