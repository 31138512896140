<template>
  <section class="komtec">
    <div class="komtec__container _container">
      <div class="komtec__content">
        <h2 class="komtec__title title">
          {{$t('main.welcome', {company: 'KOMTEC'})}}
        </h2>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "VKomtec"
};
</script>

<style lang="scss" scoped>
.komtec {
  width: 100%;
  background-image: url("../assets/images/bg-920.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @include for-tablet-portrait-up {
    background-image: url("../assets/images/bg-1280.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  @include for-tablet-landscape-up {
    background-image: url("../assets/images/bg.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  @include for-desktop-up {
    background-image: url("../assets/images/bg.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  &__container {
    padding-top: 244px;
    padding-bottom: 170px;
    @include for-tablet-portrait-up {
      padding-top: 283px;
      padding-bottom: 191px;
    }
    @include for-tablet-landscape-up {
      padding-top: 341px;
      padding-bottom: 248px;
    }
    @include for-desktop-up {
      padding-top: 361px;
      padding-bottom: 331px;
    }
  }
  &__title {
    font-family: "Merriweather", serif;
    @include H6();
    color: $white-color;
    text-align: center;
    @include for-tablet-portrait-up {
      @include H3();
    }
    @include for-tablet-landscape-up {
      @include H1();
    }
    @include for-desktop-up {
      font-size: 46px;
      line-height: 58px;
    }
  }
}
</style>
