<template>
  <nav class="menu__body">
    <ul class="menu__list">
      <li
        v-for="item of $t('header.nav')"
        :key="item.id"
        @mouseover="item.submenu ? showList() : ''"
        @mouseleave="item.submenu ? hideList() : ''"
      >
        <template v-if="item.submenu">
          <span class="menu__link has-children">{{ item.name }}</span>
          <transition name="submenu-list">
            <div class="submenu__list" v-show="isActive">
              <router-link
                v-for="x of item.submenu"
                :key="x.id"
                :to="{ path: `${x.link}`, hash: `${x.hash}` }"
                class="submenu__link"
                >{{ x.name }}</router-link>
            </div>
          </transition>
        </template>
        <template v-else-if="item.site_link">
          <a :href="item.site_link" target="_blank" class="menu__link">{{item.name}}</a>
        </template>
        <template v-else>
          <router-link
            :to="{ path: `${item.link}`, hash: `${item.hash}` }"
            class="menu__link"
            exact
          >
            {{ item.name }}
          </router-link>
        </template>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "VNavbar",
  data: function() {
    return {
      isActive: false
    };
  },
  methods: {
    showList() {
      this.isActive = true;
    },
    hideList() {
      this.isActive = false;
    }
  }
};
</script>

<style lang="scss">
.menu__body {
  .menu__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 390px;
    li {
      max-width: 20%;
      position: relative;
      @include for-tablet-landscape-up {
        max-width: none;
      }
    }
    @include for-tablet-portrait-up {
      align-items: flex-start;
    }
    @include for-tablet-landscape-up {
      width: 401px;
    }
    @include for-desktop-up {
      width: 463px;
    }
  }
  .menu__link,
  .submenu__link {
    font-family: "Manrope", sans-serif;
    font-size: 14px;
    line-height: 19px;
    text-decoration: none;
    @include for-tablet-landscape-up {
      font-size: 16px;
      line-height: 22px;
    }
    @include for-desktop-up {
      font-size: 20px;
      line-height: 27px;
    }
  }
  .menu__link {
    color: $super-dark-gray-color;
    &:hover {
      cursor: pointer;
      text-shadow: 0px 0px 0px rgb(0, 0, 0, 1);
    }
    &::after {
      display: block;
      position: absolute;
      top: calc(100% + 6px);
      right: 0;
      width: 0;
      height: 2px;
      border-radius: 4px;
      content: "";
      background: $main-color;
      transition: width 0.3s ease-out;
    }
    &::before {
      display: block;
      position: absolute;
      top: -6px;
      left: 0;
      width: 0;
      height: 2px;
      border-radius: 4px;
      content: "";
      background: $main-color;
      transition: width 0.3s ease-out;
    }
    &:target::after {
      width: 30px;
    }
    &:target::before {
      width: 30px;
    }
    &.router-link-exact-active::after {
      width: 30px;
    }
    &.router-link-exact-active::before {
      width: 30px;
    }
  }
  .submenu__link {
    color: $white-color;
    &:hover {
      cursor: pointer;
      text-shadow: 0px 0px 0px rgb(255, 255, 255, 1);
    }
  }
  .submenu__link + .submenu__link {
    @include for-tablet-portrait-up {
      margin-top: 10px;
    }
    @include for-tablet-landscape-up {
      margin-top: 12px;
    }
    @include for-desktop-up {
      margin-top: 14px;
    }
  }
  .submenu__list {
    position: absolute;
    top: calc(100% + 15px);
    left: 0;
    background: rgba(14, 14, 14, 0.5);
    backdrop-filter: blur(30px);
    border-radius: 10px;
    padding: 16px;
    min-width: 220px;
    display: flex;
    flex-direction: column;
    @include for-tablet-landscape-up {
      min-width: 251px;
    }
    @include for-desktop-up {
      min-width: 281px;
    }
  }
  .submenu-list-enter-active,
  .submenu-list-leave-active {
    transition: transform 0.2s ease;
  }
  .submenu-list-enter,
  .submenu-list-leave-to {
    transform: translateY(-20px);
    transition: transform 0.2s ease-in;
  }
}
</style>
