<template>
  <section class="it-services">
    <div class="it-services__container _container">
      <div class="it-services__header section__title" data-aos="fade-up">
        <div class="vertical-line"></div>
        <div>{{ $t("it-services.title") }}</div>
      </div>
      <div class="it-services__content" data-aos="fade-up">
        <VueSlickCarousel
          ref="description"
          :as-nav-for="$refs.images"
          :arrows="true"
          :swipe="false"
          :touchMove="false"
        >
          <div
            class="slick-item"
            v-for="item of $t('it-services.items')"
            :key="item.id"
          >
            <div class="slick-item__title">{{ item.title }}</div>
            <div class="slick-item__text" v-if="item.text" v-html="item.text">
            </div>
            <TabView v-if="item.items_tabs">
              <TabPanel
                v-for="x of item.items_tabs"
                :key="x.id"
                :header="x.tab_title"
              >
                <div class="slick-item__text" v-html="x.tab_content"></div>
              </TabPanel>
            </TabView>
          </div>
        </VueSlickCarousel>
        <VueSlickCarousel
          ref="images"
          :as-nav-for="$refs.description"
          :fade="true"
          :arrows="false"
          class="images"
        >
          <div class="image">
            <picture>
              <source
                srcset="
                  ../assets/images/laptop(580x440).jpg,
                  ../assets/images/laptop(580x440)2x.jpg 2x
                "
                type="image/jpeg"
                media="(min-width: 1280px)"
              />
              <source
                srcset="
                  ../assets/images/laptop(478x390).jpg,
                  ../assets/images/laptop(478x390)2x.jpg 2x
                "
                type="image/jpeg"
                media="(min-width: 992px)"
              />
              <source
                srcset="
                  ../assets/images/laptop(357x320).jpg,
                  ../assets/images/laptop(357x320)2x.jpg 2x
                "
                type="image/jpeg"
                media="(min-width: 768px)"
              />
              <source
                srcset="
                  ../assets/images/laptop(340x260).jpg,
                  ../assets/images/laptop(340x260)2x.jpg 2x
                "
                type="image/jpeg"
                media="(max-width: 767px)"
              />
              <img
                src="../assets/images/laptop(580x440).jpg"
                alt="laptop"
                loading="lazy"
              />
            </picture>
          </div>
          <div class="image">
            <picture loading="lazy">
              <source
                srcset="
                  ../assets/images/tablet(580x440).jpg,
                  ../assets/images/tablet(580x440)2x.jpg 2x
                "
                type="image/jpeg"
                media="(min-width: 1280px)"
              />
              <source
                srcset="
                  ../assets/images/tablet(478x390).jpg,
                  ../assets/images/tablet(478x390)2x.jpg 2x
                "
                type="image/jpeg"
                media="(min-width: 992px)"
              />
              <source
                srcset="
                  ../assets/images/tablet(357x320).jpg,
                  ../assets/images/tablet(357x320)2x.jpg 2x
                "
                type="image/jpeg"
                media="(min-width: 768px)"
              />
              <source
                srcset="
                  ../assets/images/tablet(340x260).jpg,
                  ../assets/images/tablet(340x260)2x.jpg 2x
                "
                type="image/jpeg"
                media="(max-width: 767px)"
              />
              <img
                src="../assets/images/tablet(580x440).jpg"
                alt="tablet"
                loading="lazy"
              />
            </picture>
          </div>
        </VueSlickCarousel>
      </div>
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";

export default {
  name: "VItServices",
  components: { VueSlickCarousel, TabView, TabPanel },
  mounted() {
    this.$nextTick(this.$forceUpdate);
  }
};
</script>

<style lang="scss">
.it-services {
  padding-top: 60px;
  @include for-tablet-portrait-up {
    padding-top: 80px;
  }
  @include for-tablet-landscape-up {
    padding-top: 104px;
  }
  @include for-desktop-up {
    padding-top: 110px;
  }
  &__header {
    padding-bottom: 24px;
    @include for-tablet-portrait-up {
      padding-bottom: 26px;
    }
    @include for-tablet-landscape-up {
      padding-bottom: 40px;
    }
    @include for-desktop-up {
      padding-bottom: 50px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    @include for-tablet-portrait-up {
      flex-direction: row-reverse;
    }
  }
  .slick-slider + .slick-slider {
    margin-top: 54px;
    @include for-tablet-portrait-up {
      margin-top: 0px;
      margin-right: 24px;
    }
    @include for-tablet-landscape-up {
      margin-right: 36px;
    }
    @include for-desktop-up {
      margin-right: 40px;
    }
  }
  .slick {
    &-track {
      height: auto !important;
    }
    &-slide {
      height: 0;
      // visibility: hidden;
    }
    &-active {
      height: auto;
      // visibility: visible;
    }
    &-slider {
      height: auto;
      @include for-tablet-portrait-up {
        width: calc((100% - 24px) / 2);
        max-width: calc((100% - 24px) / 2);
      }
      @include for-tablet-landscape-up {
        width: calc((100% - 36px) / 2);
        max-width: calc((100% - 36px) / 2);
      }
      @include for-desktop-up {
        width: calc((100% - 40px) / 2);
        max-width: calc((100% - 40px) / 2);
      }
    }
    &-arrow {
      position: absolute;
      top: calc(100% + 20px);
      @include for-tablet-portrait-up {
        top: 100%;
      }
      @include for-tablet-landscape-up {
        top: calc(100% - 15px);
      }
      &.slick-prev {
        left: 0;
        &::before {
          content: url("../assets/icons/prev-arrow.svg");
        }
      }
      &.slick-next {
        left: 70px;
        &:before {
          content: url("../assets/icons/next-arrow.svg");
        }
      }
    }
    &-item {
      &__title {
        font-family: "Merriweather", serif;
        color: $super-dark-gray-color;
        @include P3();
        padding-bottom: 14px;
        @include for-tablet-portrait-up {
          @include P2();
          padding-bottom: 16px;
        }
        @include for-tablet-landscape-up {
          @include H6();
          padding-bottom: 24px;
        }
        @include for-desktop-up {
          @include H5();
          padding-bottom: 30px;
        }
      }
      &__text {
        font-family: "Manrope", sans-serif;
        @include P4();
        @include for-tablet-landscape-up {
          @include P3();
        }
        p + p {
          margin-top: 8px;
          @include for-tablet-portrait-up {
            margin-top: 10px;
          }
          @include for-desktop-up {
            margin-top: 20px;
          }
        }
      }
    }
  }
  .p-tabview .p-tabview-nav {
    flex-wrap: nowrap;
    overflow-x: auto;
    scroll-behavior: auto;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    scrollbar-width: none;
    margin-bottom: 22px;
    &::-webkit-scrollbar {
      width: 0;
    }
    li + li {
      margin-left: 15px;
      @include for-tablet-portrait-up {
        margin-left: 0px;
      }
    }
    @include for-tablet-portrait-up {
      justify-content: space-between;
    }
    @include for-tablet-landscape-up {
      margin-bottom: 24px;
    }
  }
  .p-tabview-nav-link {
    white-space: nowrap;
    font-family: "Manrope", sans-serif;
    color: $gray-color;
    @include P4();
    @include for-tablet-landscape-up {
      @include P2();
    }
    padding-top: 8px;
    padding-bottom: 8px;
    position: relative;
    &::after {
      display: block;
      position: absolute;
      top: calc(100% - 2px);
      right: 0;
      width: 0;
      height: 2px;
      border-radius: 4px;
      content: "";
      background: $main-color;
      transition: width 0.3s ease-out;
    }
    &::before {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 2px;
      border-radius: 4px;
      content: "";
      background: $main-color;
      transition: width 0.3s ease-out;
    }
  }
  .p-highlight > .p-tabview-nav-link {
    color: $black-color;
    &::after {
      width: 40px;
    }
    &::before {
      width: 40px;
    }
  }
  .p-tabview-panels {
    height: auto;
  }
  .images {
    .image {
      position: relative;
      // padding: 0 0 58% 0;
      max-width: 340px;
      min-height: 260px;
      overflow: hidden;
      display: block !important;
      // margin: 0;
      @include for-tablet-portrait-up {
        padding: 0;
        max-width: none;
        min-height: 320px;
      }
      @include for-tablet-landscape-up {
        min-height: 390px;
      }
      @include for-desktop-up {
        min-height: 440px;
      }
      img,
      source {
        height: 100%;
        object-fit: cover;
        object-position: center;
        position: absolute;
        top: 0;
        left: 0;
        @include for-tablet-portrait-up {
          width: 100%;
        }
      }
    }
  }
}
</style>
