<template>
  <section class="bicycle-sharing">
    <div class="bicycle-sharing__container _container">
      <div class="bicycle-sharing__content">
        <div class="bicycle-sharing__text bicycle-sharing__column" data-aos="fade-left">
          <div class="bicycle-sharing__header section__title">
            <div class="vertical-line"></div>
            <div class="bicycle-sharing__title">
              {{ $t("bycicle_sharing.title") }}
            </div>
          </div>
          <div v-html="$t('bycicle_sharing.text')">
          </div>
        </div>
        <div class="bicycle-sharing__image bicycle-sharing__column" data-aos="fade-right">
          <picture>
            <source
              srcset="
                ../assets/images/kolobike(580x440).jpg,
                ../assets/images/kolobike(580x440)2x.jpg 2x
              "
              type="image/jpeg"
              media="(min-width: 1280px)"
            />
            <source
              srcset="
                ../assets/images/kolobike(478x390).jpg,
                ../assets/images/kolobike(478x390)2x.jpg 2x
              "
              type="image/jpeg"
              media="(min-width: 992px)"
            />
            <source
              srcset="
                ../assets/images/kolobike(357x320).jpg,
                ../assets/images/kolobike(357x320)2x.jpg 2x
              "
              type="image/jpeg"
              media="(min-width: 768px)"
            />
            <source
              srcset="
                ../assets/images/kolobike(340x260).jpg,
                ../assets/images/kolobike(340x260)2x.jpg 2x
              "
              type="image/jpeg"
              media="(max-width: 767px)"
            />
            <img src="../assets/images/kolobike(580x440).jpg" alt="kolobike" loading="lazy"/>
          </picture>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "VBicycleSharing"
};
</script>

<style lang="scss" scoped>
.bicycle-sharing {
  &__container {
    padding-top: 64px;
    @include for-tablet-portrait-up {
      padding-top: 80px;
    }
    @include for-tablet-landscape-up {
      padding-top: 90px;
    }
    @include for-desktop-up {
      padding-top: 100px;
    }
  }
  &__header {
    padding-bottom: 24px;
    @include for-tablet-portrait-up {
      padding-bottom: 14px;
    }
    @include for-tablet-landscape-up {
      padding-bottom: 40px;
    }
    @include for-desktop-up {
      padding-bottom: 50px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    @include for-tablet-portrait-up {
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }
  &__column {
    @include for-tablet-portrait-up {
      width: calc((100% - 24px) / 2);
    }
    @include for-tablet-landscape-up {
      width: calc((100% - 36px) / 2);
    }
    @include for-desktop-up {
      width: calc((100% - 40px) / 2);
    }
  }
  &__column + &__column {
    margin-top: 20px;
    @include for-tablet-portrait-up {
      margin-top: 0px;
      margin-right: 24px;
    }
    @include for-tablet-landscape-up {
      margin-right: 36px;
    }
    @include for-desktop-up {
      margin-right: 40px;
    }
  }
  &__text {
    font-family: "Manrope", sans-serif;
    @include P4();
    @include for-tablet-landscape-up {
      @include P3();
    }
    p + p {
      margin-top: 8px;
      @include for-tablet-portrait-up {
        margin-top: 10px;
      }
      @include for-desktop-up {
        margin-top: 20px;
      }
    }
  }
  &__image {
    position: relative;
    // padding: 0 0 58% 0;
    max-width: 340px;
    min-height: 260px;
    overflow: hidden;
    // margin: 0;
    @include for-tablet-portrait-up {
      padding: 0;
      max-width: none;
      min-height: 320px;
    }
    @include for-tablet-landscape-up {
      min-height: 390px;
    }
    @include for-desktop-up {
      min-height: 440px;
    }
    img,
    source {
      height: 100%;
      max-width: 100%;
      object-fit: cover;
      object-position: center;
      position: absolute;
      top: 0;
      left: 0;
      @include for-tablet-portrait-up {
        width: 100%;
      }
    }
  }
}
</style>
