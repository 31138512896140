<template>
  <div id="burger" :class="{ active: isBurgerActive }" @click.prevent="toggle">
    <slot>
      <button type="button" class="burger-button" title="Menu">
        <span class="burger-bar burger-bar--1"></span>
        <span class="burger-bar burger-bar--2"></span>
        <span class="burger-bar burger-bar--3"></span>
      </button>
    </slot>
  </div>
</template>
<script>
import { store, mutations } from "../../store/app/store.js";

export default {
  name: "VBurger",
  computed: {
    isBurgerActive() {
      return store.isNavOpen;
    }
  },
  methods: {
    toggle() {
      mutations.toggleNav();
      document.body.style.overflow = store.isNavOpen ? "hidden" : "";
    }
  }
};
</script>

<style lang="scss">
button {
  cursor: pointer;
  &:focus {
    outline: 0;
  }
}

.burger-button {
  position: relative;
  height: 14px;
  width: 28px;
  display: block;
  z-index: 11;
  border: 0;
  border-radius: 0;
  background-color: transparent;
  pointer-events: all;
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  padding: 0;
}

.burger-bar {
  background-color: $super-dark-gray-color;
  position: absolute;
  top: 50%;
  right: 0;
  height: 2px;
  width: 100%;
  border-radius: 2px;
  margin-top: -1px;
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1),
    opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1),
    background-color 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.burger-bar--1 {
  -webkit-transform: translateY(-7px);
  transform: translateY(-7px);
}

.burger-bar--2 {
  transform-origin: 100% 50%;
}

.burger-button:hover .burger-bar--3 {
  transform: scaleX(1) translateY(7px);
}

.no-touchevents .burger-bar--3:hover {
  transform: scaleX(1) translateY(7px);
}

.burger-bar--3 {
  transform: scaleX(0.8) translateY(7px) translateX(3px);
}

#burger.active .burger-button {
  transform: rotate(-180deg);
}

#burger.active .burger-bar {
  background-color: $white-color;
}

#burger.active .burger-bar--1 {
  transform: rotate(45deg);
}

#burger.active .burger-bar--2 {
  opacity: 0;
}

#burger.active .burger-bar--3 {
  transform: scaleX(1) rotate(-45deg);
}
</style>
