<template>
  <footer class="footer">
    <div class="footer__content _container">
      <div class="footer__links">
        <div class="footer__column">
          <router-link to="/" class="footer__logo">
            <img src="../assets/Logo-dark.png" alt="Komtec logo" />
          </router-link>
          <router-link
            to="/privacy-policy"
            v-if="!isMobileView"
            class="footer__privacy footer-link"
          >
            {{ $t("footer.privacy") }}
          </router-link>
        </div>
        <div class="footer__menu" v-if="!isMobileView">
          <ul class="menu__list">
            <li v-for="item of $t('footer.nav')" :key="item.id">
              <template v-if="item.site_link">
                <a
                  :href="item.site_link"
                  target="_blank"
                  class="menu__link footer-link"
                  >{{ item.name }}</a
                >
              </template>
              <template v-else>
                <router-link
                  :to="{ path: `${item.link}`, hash: `${item.hash}` }"
                  class="menu__link footer-link"
                >
                  {{ item.name }}
                </router-link>
              </template>
            </li>
          </ul>
        </div>
        <div class="footer__contacts">
          <div class="footer__nip footer-link">
            <img src="../assets/icons/books.svg" alt="Books icon" />
            <span> 5423409986 (NIP) </span>
          </div>
          <a href="mailto:biuro@komtec.pl" class="footer__email footer-link">
            <img src="../assets/icons/mail.svg" alt="Mail icon" />
            <span> biuro@komtec.pl </span>
          </a>
          <a href="tel:+48 85 722 29 05" class="footer__phone footer-link">
            <img src="../assets/icons/phone.svg" alt="Phone icon" />
            <span> +48 85 722 29 05 (Fax) </span>
          </a>
        </div>
        <div class="footer__contacts">
          <div class="footer__location">
            <a href="https://goo.gl/maps/fRSxxt2nYcDS9udd8" class="footer-link">
              <img src="../assets/icons/location.svg" alt="Location pin icon" />
              <span
                >KOMTEC Sp. z o.o. ul.Żurawia 71a, lok. 1.30, 15-540 Białystok,
                Polska
              </span>
            </a>
          </div>
          <div class="footer__icons">
            <a
              href="https://www.linkedin.com/company/komtecspzoo"
              class="footer__icons__link"
              target="_blank"
            >
              <img src="../assets/icons/linkedin.svg" alt="Linkedin" />
            </a>
          </div>
        </div>
        <router-link
          to="/privacy-policy"
          v-if="isMobileView"
          class="footer__privacy-mob footer-link"
        >
          {{ $t("footer.privacy") }}
        </router-link>
      </div>
      <div class="footer__copyright">
        © 2020-2021. "KOMTEC Sp. z o.o.". All Right Reserved.
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "VFooter",
  data: () => ({
    isMobileView: false,
  }),
  methods: {
    handleView() {
      this.isMobileView = window.innerWidth < 768;
    },
  },
  created() {
    this.handleView();
    window.addEventListener("resize", this.handleView);
  },
};
</script>

<style lang="scss" scoped>
.footer {
  background: $super-dark-gray-color;
  &__content {
    display: flex;
    flex-direction: column;
  }
  &__column {
    display: flex;
    flex-direction: column;
    max-width: 148px;
    @include for-tablet-landscape-up {
      max-width: 168px;
    }
    @include for-desktop-up {
      max-width: 173px;
    }
  }
  &__privacy,
  &__privacy-mob {
    &.footer-link {
      text-decoration: underline;
    }
  }
  &__privacy {
    padding-top: 14px;
    @include for-tablet-landscape-up {
      padding-top: 20px;
    }
    @include for-desktop-up {
      padding-top: 24px;
    }
  }
  &__privacy-mob {
    padding-top: 20px;
  }
  &__copyright {
    display: flex;
    justify-content: center;
    font-family: "Manrope", sans-serif;
    color: $dark-gray-color;
    border-top: 1px solid $dark-gray-color;
    padding-top: 12px;
    padding-bottom: 12px;
    @include P4();
    @include for-tablet-portrait-up {
      justify-content: flex-start;
      padding-top: 10px;
      padding-bottom: 10px;
    }
    @include for-tablet-landscape-up {
      padding-top: 16px;
      padding-bottom: 16px;
      @include P3();
    }
  }
  &__links {
    padding-top: 40px;
    padding-bottom: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include for-tablet-portrait-up {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding-top: 50px;
      padding-bottom: 18px;
    }
    @include for-tablet-landscape-up {
      padding-top: 60px;
      padding-bottom: 20px;
    }
    @include for-desktop-up {
      padding-top: 80px;
      padding-bottom: 30px;
    }
  }
  .menu__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    li + li {
      margin-top: 10px;
      @include for-tablet-landscape-up {
        margin-top: 12px;
      }
    }
  }
  &__logo {
    margin-bottom: 30px;
    img {
      width: 118px;
      height: 40px;
      @include for-tablet-landscape-up {
        width: 149px;
        height: 51px;
      }
      @include for-desktop-up {
        width: 164px;
        height: 56px;
      }
    }
    @include for-tablet-portrait-up {
      margin-bottom: 0;
    }
  }
  &-link {
    font-family: "Manrope", sans-serif;
    color: $white-color;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    @include P4();
    @include for-tablet-portrait-up {
      justify-content: flex-start;
    }
    @include for-tablet-landscape-up {
      @include P3();
    }
    img {
      margin-right: 6px;
      @include for-tablet-landscape-up {
        margin-right: 10px;
      }
    }
    &:hover {
      text-shadow: 0px 0px 0px rgba(255, 255, 255, 1);
    }
  }
  .footer-link + .footer-link {
    margin-top: 10px;
    @include for-tablet-landscape-up {
      margin-top: 12px;
    }
  }
  &__location {
    max-width: 166px;
    padding-top: 18px;
    text-align: center;
    @include for-tablet-portrait-up {
      padding-top: 0px;
      max-width: 176px;
      text-align: left;
    }
    @include for-tablet-landscape-up {
      max-width: 205px;
    }
  }
  &__icons {
    display: flex;
    justify-content: center;
    @include for-tablet-portrait-up {
      justify-content: flex-start;
    }
    &__link + &__link {
      margin-left: 16px;
    }
  }
  &__location + &__icons{
    margin-top: 18px;
    @include for-tablet-portrait-up {
      margin-top: 10px;
    }
    @include for-tablet-landscape-up {
      margin-top: 12px;
    }
  }
}
</style>
