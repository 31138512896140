<template>
  <main class="page">
    <v-komtec class="section" id="komtec"></v-komtec>
    <v-about-company class="section" id="about-company"></v-about-company>
    <v-it-services class="section" id="it-services"></v-it-services>
    <v-our-advantages class="section" id="advantages"></v-our-advantages>
    <v-international-trade class="section" id="international-trade"></v-international-trade>
    <v-bicycle-sharing class="section" id="bicycle-sharing"></v-bicycle-sharing>
    <!-- <v-digital class="section" id="digital"></v-digital> -->
    <v-consultation class="section" id="contacts"></v-consultation>
  </main>
</template>

<script>
import VKomtec from "../components/VKomtec";
import VAboutCompany from "../components/VAboutCompany";
import VOurAdvantages from "../components/VOurAdvantages";
import VInternationalTrade from "../components/VInternationalTrade";
import VBicycleSharing from "../components/VBicycleSharing";
import VConsultation from "../components/VConsultation";
import VItServices from "../components/VItServices";
// import VDigital from "../components/VDigital.vue";

export default {
  name: "Name",
  components: {
    VKomtec,
    VAboutCompany,
    VOurAdvantages,
    VInternationalTrade,
    VBicycleSharing,
    VConsultation,
    VItServices
    // VDigital
  }
};
</script>

<style lang="scss" scoped>
.page {
  overflow: hidden;
}
</style>
