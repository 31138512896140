<template>
  <header class="header">
    <div class="header__content _container">
      <h1 class="header__title title-hidden">Komtec</h1>
      <div class="header__logo logo">
        <router-link to="/" class="menu__logo">
          <img
            src="../assets/images/logo.png"
            alt="Komtec logo"
            class="menu__logo-image"
          />
        </router-link>
      </div>
      <div class="header__menu menu">
        <v-burger class="menu__icon icon-menu" v-if="isMobileView"></v-burger>
        <v-sidebar v-if="isMobileView"></v-sidebar>
        <template v-else>
          <v-navbar></v-navbar>
        </template>
      </div>
      <v-language-switcher class="desktop" :isMobile="false" v-if="!isMobileView"/>
    </div>
  </header>
</template>

<script>
import VBurger from "../components/Menu/VBurger";
import VNavbar from "../components/Menu/VNavbar";
import VSidebar from "../components/Menu/VSidebar";
import VLanguageSwitcher from "../components/Menu/VLanguageSwitcher"

export default {
  name: "VHeader",
  components: { VBurger, VNavbar, VSidebar, VLanguageSwitcher },
  data: () => ({
    isMobileView: false
  }),
  methods: {
    handleView() {
      this.isMobileView = window.innerWidth < 768;
    }
  },
  created() {
    this.handleView();
    window.addEventListener("resize", this.handleView);
  }
};
</script>

<style lang="scss">
.title-hidden {
  position: absolute;
  overflow: hidden;
  height: 1px;
  width: 1px;
}
.header {
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100vw;
  max-width: 100%;
  background: $white-color;
  box-shadow: 0px 5px 13px rgba(0, 0, 0, 0.05);
  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include for-tablet-portrait-up {
      align-items: flex-start;
    }
  }
  &__menu {
    display: flex;
    align-items: center;
    height: 72px;
    @include for-tablet-portrait-up {
      height: 73px;
      align-items: flex-start;
      padding-top: 28px;
    }
    @include for-tablet-landscape-up {
      height: 93px;
      padding-top: 35px;
    }
    @include for-desktop-up {
      height: 99px;
      padding-top: 44px;
    }
  }
  &__logo {
    @include for-tablet-portrait-up {
      padding-top: 20px;
    }
    @include for-tablet-landscape-up {
      padding-top: 21px;
    }
    @include for-desktop-up {
      padding-top: 30px;
    }
  }
}
.menu {
  &__logo-image {
    width: 118px;
    height: 40px;
    @include for-tablet-landscape-up {
      width: 149px;
      height: 51px;
    }
    @include for-desktop-up {
      width: 164.19px;
      height: 55.5px;
    }
  }
}
</style>
