<template>
  <div class="consultation-form">
    <div class="consultation-form__container">
      <div class="consultation-form__header section__title">
        <div class="vertical-line"></div>
        <div class="consultation-form__title">
              {{ $t("consultation_form.title") }}          
        </div>
      </div>
      <div class="consultation-form__content">
        <div class="form">
          <form @submit.prevent="submit">
            <div class="form__group">
              <label class="form__label">{{ $t("consultation_form.name.label") }}</label>
              <input
                name="name"
                class="input"
                type="text"
                v-model.trim="form.name"
                :placeholder="$t('consultation_form.name.placeholder')"
              />
            </div>
            <div class="form__group">
              <label class="form__label">{{ $t("consultation_form.phone.label") }}</label>
              <input
                name="phone"
                class="input"
                type="text"
                v-model.trim="form.phone"
                :placeholder="$t('consultation_form.phone.placeholder')"
              />
            </div>
            <div class="form__group">
              <label class="form__label">{{ $t("consultation_form.email.label") }}</label>
              <input
                name="email"
                class="input"
                type="text"
                v-model.trim="form.email"
                :placeholder="$t('consultation_form.email.placeholder')"
              />
            </div>
            <div class="form__group">
              <label class="form__label">{{ $t("consultation_form.message.label") }}</label>
              <textarea
                name="comment"
                v-model.trim="form.comment"
                :placeholder="$t('consultation_form.message.placeholder')"
                @input="textAreaAdjust"
                class="textarea"
              ></textarea>
            </div>
            <div class="form__group form__group-checkbox">
              <input type="checkbox" class="checkbox" v-model="isChecked" />
              <label class="label-checkbox">
                {{$t('consultation_form.agreement_text')}}
                <router-link to='/privacy-policy'>
                  {{$t('consultation_form.link_to_policy')}}
                </router-link>
              </label>
            </div>
            <div class="form__button">
              <button type="submit" :disabled="!isChecked">
                {{ $t("consultation_form.button") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formsService from "../services/forms.services";

export default {
  name: "VConsultationForm",
  data: () => ({
    form: {
      name: "",
      phone: "",
      email: "",
      comment: ""
    },
    isChecked: false
  }),
  methods: {
    async submit() {
      await formsService.sendForm(
        this.form.name,
        this.form.phone,
        this.form.email,
        this.form.comment
      );
    },
    textAreaAdjust: function(e) {
      e.target.style.height = "auto";
      e.target.style.height = e.target.scrollHeight + "px";
    }
  },
  mounted() {
    let textarea = document.querySelectorAll(".textarea");
    textarea.forEach(item => {
      item.setAttribute(
        "style",
        "height:" + textarea.scrollHeight + "px;overflow-y:hidden;"
      );
    });
  }
};
</script>

<style lang="scss">
.consultation-form {
  &__header {
    padding-bottom: 30px;
    @include for-tablet-landscape-up {
      padding-bottom: 40px;
    }
    @include for-desktop-up {
      padding-bottom: 50px;
    }
  }
  .form {
    width: 100%;
    display: flex;
    justify-content: center;
    form {
      width: 100%;
      max-width: 340px;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include for-tablet-portrait-up {
        max-width: 100%;
        align-items: center;
      }
    }
    &__group {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 340px;
      @include for-tablet-portrait-up {
        max-width: none;
      }
    }
    .form__group + .form__group {
      margin-top: 26px;
      @include for-tablet-landscape-up {
        margin-top: 30px;
      }
    }
    &__label {
      font-family: "Manrope", sans-serif;
      @include P3();
      color: $super-dark-gray-color;
      margin-bottom: 6px;
      @include for-tablet-landscape-up {
        @include P2();
        margin-bottom: 10px;
      }
    }
    .input {
      font-family: "Manrope", sans-serif;
      border: 1px solid $gray-color;
      box-sizing: border-box;
      border-radius: 40px;
      padding: 11px 16px;
      color: $super-dark-gray-color;
      caret-color: $super-dark-gray-color;
      @include P4();
      &:focus {
        color: $super-dark-gray-color;
        background: none;
        border: 1px solid $main-color;
      }
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus {
        font-family: "Montserrat", sans-serif;
        -webkit-text-fill-color: $super-dark-gray-color;
        box-shadow: 0 0 0px 1000px transparent inset;
        transition: background-color 5000s ease-in-out 0s;
        background: -webkit-linear-gradient(
          top,
          rgba(255, 255, 255, 0) 0%,
          rgba(0, 174, 255, 0.04) 50%,
          rgba(255, 255, 255, 0) 51%,
          rgba(0, 174, 255, 0.03) 100%
        );
      }
      @include for-tablet-landscape-up {
        padding: 12px 18px;
        @include P3();
      }
    }
    .textarea {
      border: 1px solid $gray-color;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 11px 16px;
      min-height: 80px;
      width: 100%;
      min-width: 100%;
      max-width: 100%;
      font-family: "Manrope", sans-serif;
      caret-color: $super-dark-gray-color;
      @include P4();
      &:focus {
        color: $super-dark-gray-color;
        background: none;
        border: 1px solid $main-color;
      }
      @include for-tablet-landscape-up {
        padding: 12px 18px;
        @include P3();
      }
    }
    .form__group.form__group-checkbox {
      display: flex;
      flex-direction: row;
    }
    .checkbox {
      outline: none;
      border: 1px solid $gray-color;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-radius: 5px;
      width: 26px;
      min-width: 26px;
      height: 26px;
      cursor: pointer;
      position: relative;
      &::before {
        content: url("../assets/icons/checkbox.svg");
        position: absolute;
        right: 50%;
        bottom: 8px;
        transform: translate(50%, 50%);
        opacity: 0;
        transition: 0.1s ease-in;
      }
      &:checked::before {
        opacity: 1;
      }
    }
    .label-checkbox {
      font-family: "Manrope", sans-serif;
      @include P6();
      @include for-tablet-landscape-up() {
        @include P4();
      }
      a {
        color: $super-dark-gray-color;
      }
    }
    .checkbox + .label-checkbox {
      margin-left: 10px;
    }
    &__button {
      display: flex;
      justify-content: center;
      margin-top: 26px;
      @include for-tablet-landscape-up {
        justify-content: flex-start;
        margin-top: 36px;
      }
      button {
        width: 182px;
        height: 42px;
        @include button();
        font-family: "Manrope", sans-serif;
        @include P4();
        @include for-tablet-landscape-up {
          @include P3();
          width: 200px;
          height: 46px;
        }
      }
    }
  }
}
</style>
