<template>
  <section class="about-company">
    <div class="about-company__container _container">
      <div class="about-company__header section__title" data-aos="fade-up">
        <div class="vertical-line"></div>
        <div class="about-company__title">
          {{ $t("about.title") }}
        </div>
      </div>
      <div class="about-company__content">
        <div
          class="about-company__desription"
          data-aos="fade-up"
          v-html="$t('about.text', { company: 'KOMTEC' })"
        ></div>
        <div class="about-company__items">
          <div class="about-company__item areas-of-work" v-for="item of $t('about.items')" :key="item.id">
            <div class="areas-of-work__image">
              <img
                :src="require(`../assets/images/${item.image}`)"
                :alt="item.title"
                loading="lazy"
              />
            </div>
            <div class="areas-of-work__title">
              {{item.title}}
            </div>
            <div class="areas-of-work__info">
              {{item.text}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "VAboutCompany"
};
</script>

<style lang="scss" scoped>
.about-company {
  &__container {
    padding-top: 60px;
    @include for-tablet-portrait-up {
      padding-top: 71px;
    }
    @include for-tablet-landscape-up {
      padding-top: 90px;
    }
    @include for-desktop-up {
      padding-top: 81px;
    }
  }
  &__header {
    padding-bottom: 24px;
    @include for-tablet-portrait-up {
      padding-bottom: 26px;
    }
    @include for-tablet-landscape-up {
      padding-bottom: 40px;
    }
    @include for-desktop-up {
      padding-bottom: 50px;
    }
  }
  &__desription {
    font-family: "Manrope", sans-serif;
    color: $super-dark-gray-color;
    @include P3();
    @include for-tablet-landscape-up {
      @include P2();
    }
  }
  &__items {
    display: flex;
    flex-direction: column;
    @include for-tablet-portrait-up {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
  &__item {
    margin-top: 25px;
    @include for-tablet-portrait-up {
      width: calc((100% - 20px) / 2);
    }
    @include for-tablet-landscape-up {
      margin-top: 30px;
      width: calc((100% - 36px) / 2);
    }
    @include for-desktop-up {
      width: calc((100% - 40px) / 2);
    }
  }
  .areas-of-work {
    display: flex;
    flex-direction: column;
    &__image {
      padding-bottom: 14px;
      img {
        height: 40px;
        width: 40px;
        @include for-tablet-landscape-up {
          height: 46px;
          width: 46px;
        }
        @include for-desktop-up {
          height: 50px;
          width: 50px;
        }
      }
    }
    &__title {
      font-family: "Merriweather", serif;
      font-size: 16px;
      line-height: 20px;
      color: $super-dark-gray-color;
      padding-bottom: 10px;
      @include for-tablet-portrait-up {
        font-size: 18px;
        line-height: 23px;
        padding-bottom: 14px;
      }
      @include for-tablet-landscape-up {
        font-size: 22px;
        line-height: 28px;
      }
      @include for-desktop-up {
        font-size: 24px;
        line-height: 30px;
        padding-bottom: 16px;
      }
    }
    &__info {
      font-family: "Manrope", sans-serif;
      color: $dark-gray-color;
      @include P4();
      @include for-tablet-portrait-up {
        @include P3();
      }
    }
  }
}
</style>
