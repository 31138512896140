import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import App from './App.vue'

import PrimeVue from 'primevue/config'
import 'primevue/resources/primevue.min.css'

import AOS from 'aos'
import 'aos/dist/aos.css'

Vue.use(PrimeVue)
Vue.use(VueRouter)
Vue.use(Vuex)

Vue.config.productionTip = false

import router from './router'
import i18n from './i18n'

new Vue({
  render: h => h(App),
  router,
  i18n,

  created () {
    AOS.init({
      duration: 1000,
      mirror: false
    })
  }
}).$mount('#app')
