<template>
  <section class="our-advantages">
    <div class="our-advantages__container _container">
      <div class="our-advantages__header section__title" data-aos="fade-up">
        <div class="vertical-line"></div>
        <div class="our-advantages__title">
           {{ $t("advantages.title") }}
        </div>
      </div>
      <div class="our-advantages__content">
        <div class="our-advantages__items" data-aos="fade-up">
          <div class="our-advantages__item advantage" v-for="item of $t('advantages.items')" :key="item.id">
            <img
              :src="require(`../assets/images/${item.image}`)"
              :alt="item.title"
              class="advantage__image"
            />
            <div class="advantage__title">
              {{item.title}}
            </div>
          </div>
        </div>
        <!-- <div class="our-advantages__text" data-aos="fade-up" v-html="$t('advantages.text', { company: 'KOMTEC' })">
        </div>
        <a
          :href="$t('advantages.link')"
          class="our-advantages__btn"
          target="_blanc"
          data-aos="fade-up"
        >
          {{ $t("advantages.button") }}
        </a> -->
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.our-advantages {
  &__container {
    padding-top: 60px;
    @include for-tablet-landscape-up {
      padding-top: 80px;
    }
    @include for-desktop-up {
      padding-top: 100px;
    }
  }
  &__header {
    padding-bottom: 26px;
    @include for-tablet-landscape-up {
      padding-bottom: 40px;
    }
    @include for-desktop-up {
      padding-bottom: 50px;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include for-tablet-portrait-up {
      align-items: flex-start;
    }
  }
  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    @include for-tablet-portrait-up {
      justify-content: space-between;
      flex-wrap: nowrap;
      // padding-bottom: 22px;
    }
    @include for-tablet-landscape-up {
      // padding-bottom: 35px;
    }
    @include for-desktop-up {
      // padding-bottom: 50px;
    }
  }
  &__text {
    font-family: "Manrope", sans-serif;
    @include P4();
    color: $super-dark-gray-color;
    text-align: center;
    padding-bottom: 26px;
    @include for-tablet-portrait-up {
      padding-bottom: 20px;
    }
    @include for-tablet-landscape-up {
      padding-bottom: 32px;
      @include P3();
    }
    @include for-desktop-up {
      padding-bottom: 30px;
      @include P2();
    }
  }
  &__btn {
    font-family: "Manrope", sans-serif;
    text-decoration: none;
    width: 182px;
    height: 42px;
    @include P4();
    @include button();
    @include for-tablet-landscape-up {
      width: 200px;
      height: 46px;
      @include P3();
    }
  }
  .advantage + .advantage {
    @include for-tablet-portrait-up {
      border-left: 1px solid #d6d6d6;
    }
  }
  .advantage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-width: 160px;
    padding-bottom: 30px;
    &:first-child {
      padding-left: 0;
    }
    @include for-tablet-portrait-up {
      align-items: flex-start;
      max-width: calc((100% - 17px) / 5);
      padding-left: 17px;
      padding-bottom: 0px;
    }
    @include for-tablet-landscape-up {
      max-width: calc((100% - 36px) / 5);
      padding-left: 36px;
    }
    @include for-desktop-up {
      max-width: calc((100% - 44px) / 5);
      padding-left: 44px;
    }
    &__title {
      font-family: "Manrope", sans-serif;
      @include P4();
      text-align: center;
      @include for-tablet-portrait-up {
        text-align: left;
      }
      @include for-tablet-landscape-up {
        @include P3();
      }
      @include for-desktop-up {
        @include P2();
      }
    }
    &__image {
      margin-bottom: 8px;
      height: 36px;
      width: 36px;
      @include for-tablet-portrait-up {
        height: 40px;
        width: 40px;
        margin-bottom: 12px;
      }
      @include for-tablet-landscape-up {
        height: 46px;
        width: 46px;
        margin-bottom: 16px;
      }
      @include for-desktop-up {
        height: 50px;
        width: 50px;
        margin-bottom: 14px;
      }
    }
  }
}
</style>
