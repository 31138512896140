<template>
  <div class="managers-contacts">
    <div class="managers-contacts__container">
      <div class="managers-contacts__header section__title">
        <div class="vertical-line"></div>
        <div class="managers-contacts__title">
          {{$t("managers_contacts.title")}}
        </div>
      </div>
      <div class="managers-contacts__content">
        <div class="managers-contacts__items">
          <div class="managers-contacts__item manager-contacts" v-for="item of $t('managers_contacts.items')" :key="item.id">
              <img 
                class="manager-contacts__photo"
                :src="require(`../assets/images/${item.image}`)" 
                alt="Manager"  
                loading="lazy" />
            <div class="manger-contacts__info">
              <div class="manager-contacts__name">
                {{item.name}}
              </div>
              <div class="manager-contacts__position">
                {{item.position}}
              </div>
              <a
                :href="`mailto:` + item.email"
                class="manager-contacts__email contact-link"
              >
                <img src="../assets/icons/mail-dark.svg" alt="Mail icon" />
                <span>
                {{item.email}}
                </span>
              </a>
              <a
                :href="`tel:` + item.phone"
                class="manager-contacts__phone contact-link"
              >
                <img src="../assets/icons/phone-dark.svg" alt="Phone icon" />
                <span>{{item.phone}} (Whatsapp/Viber) </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <p class="managers-contacts__text">
        {{$t("managers_contacts.text")}}
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.managers-contacts {
  &__header {
    padding-bottom: 26px;
    @include for-tablet-portrait-up {
      padding-bottom: 35px;
    }
    @include for-tablet-landscape-up {
      padding-bottom: 55px;
    }
    @include for-desktop-up {
      padding-bottom: 50px;
    }
  }
  &__text {
    font-family: Manrope;
    @include P4();
    text-align: center;
    color: $super-dark-gray-color;
    margin-top: 26px;
    @include for-tablet-portrait-up {
      text-align: left;
      margin-top: 28px;
    }
    @include for-tablet-landscape-up {
      @include P3();
      margin-top: 34px;
    }
    @include for-desktop-up {
      margin-top: 40px;
    }
  }
  &__items {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include for-tablet-portrait-up {
      align-items: flex-start;
    }
  }
  &__item + &__item {
    margin-top: 24px;
    @include for-tablet-portrait-up {
      margin-top: 26px;
    }
    @include for-tablet-landscape-up {
      margin-top: 40px;
    }
  }
  .manager-contacts {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include for-tablet-portrait-up {
      flex-direction: row;
    }
    &__photo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 110px;
      height: 110px;
      border: 1px solid $gray-color;
      background: $white-color;
      border-radius: 5px;
      margin-bottom: 14px;
      object-fit: cover;
      @include for-tablet-portrait-up {
        margin-bottom: 0;
        margin-right: 12px;
      }
      @include for-tablet-landscape-up {
        width: 150px;
        height: 150px;
        margin-right: 20px;
      }
      @include for-desktop-up {
        width: 160px;
        height: 160px;
      }
    }
    &__name {
      font-family: "Manrope", sans-serif;
      @include P3-bold();
      color: $super-dark-gray-color;
      text-align: center;
      margin-bottom: 4px;
      @include for-tablet-portrait-up {
        text-align: left;
      }
      @include for-tablet-landscape-up {
        @include P1-bold();
        margin-bottom: 8px;
      }
      @include for-desktop-up {
        @include H6();
      }
    }
    &__position {
      font-family: "Manrope", sans-serif;
      @include P6-light();
      color: $dark-gray-color;
      text-align: center;
      margin-bottom: 14px;
      @include for-tablet-portrait-up {
        text-align: left;
      }
      @include for-tablet-landscape-up {
        @include P4-light();
        margin-bottom: 16px;
      }
      @include for-desktop-up {
        @include P3-light();
        margin-bottom: 18px;
      }
    }
    &__email {
      margin-bottom: 6px;
      @include for-tablet-landscape-up {
        margin-bottom: 10px;
      }
      @include for-desktop-up {
        margin-bottom: 12px;
      }
    }
    .contact-link {
      font-family: "Manrope", sans-serif;
      color: $super-dark-gray-color;
      text-align: center;
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
      @include P4();
      @include for-tablet-portrait-up {
        justify-content: flex-start;
      }
      @include for-tablet-landscape-up {
        @include P3();
      }
      @include for-desktop-up {
        @include P2();
      }
      img {
        margin-right: 4px;
        @include for-tablet-landscape-up {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
